
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"
import Tagify from '@yaireo/tagify';
import {dateConvert} from "@/core/filters/datatime";
import airlineclassestype from "@/core/data/airlineclassestype";

interface newItemData {
    itemTitle: string;
    itemStart: string;
    itemFinish: string;
    itemMarket: string;
    itemAccount: Array<any>;
}

export default defineComponent({
    name: "new-over-commission-modal",
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    data() {
        return {
            startpicker: null,
            endpicker: null,
        }
    },
    mounted() {
        this.datapicker();
        return true;
    },
    methods: {
        datapicker() {
            let config = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
            };
            this.startpicker = flatpickr("#kt_datepicker_1", config);
            this.endpicker = flatpickr("#kt_datepicker_2", config);
        },
    },
    props: {
        hotelID: String,
    },
    setup() {
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const newRoomModalRef = ref<null | HTMLElement>(null);
        const arrAirlineClasses = ref ([
          {  Type:airlineclassestype.TYPES[0].Value, OneWay : "",RoundTrip : "",},
          {  Type:airlineclassestype.TYPES[1].Value, OneWay : "",RoundTrip : "",},
          {  Type:airlineclassestype.TYPES[2].Value, OneWay : "",RoundTrip : "",},
          {  Type:airlineclassestype.TYPES[3].Value, OneWay : "",RoundTrip : "",},
        ]);


        const myDetails2 = computed(() => {
            return store.getters.suppliersDetails;
        });



        const newItemData = ref<newItemData>({
            itemTitle: "",
            itemStart: "",
            itemFinish: "",
            itemMarket: "",
            itemAccount: [],
        });

        const validationSchema = Yup.object().shape({
            itemTitle: Yup.string().required().label("Başlık"),
            itemStart: Yup.string().required().label("Başlangıç Tarihi"),
            itemFinish: Yup.string().required().label("Bitiş Tarihi"),
        });
        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }
            //Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");


            const payload1 = {
                SupplierID: myDetails2.value.ID,
                Title: newItemData.value.itemTitle,
                StartDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemStart),
                FinishDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemFinish),
                Markets: JSON.stringify(newItemData.value.itemMarket),
                Accounts: JSON.stringify(newItemData.value.itemAccount),
                AirlineClasses: JSON.stringify(arrAirlineClasses.value),
            }
            //console.log(payload1);

            store.dispatch(Actions.FLIGHT_SUPPLIER_PROFITS_INSERT, payload1)
                .then(() => {
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.", confirmButtonText: "Geri Dön!",
                        icon: "success", buttonsStyling: false,  customClass: {confirmButton: "btn fw-bold btn-light-primary",},
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(newRoomModalRef.value);
                    });
                }).catch(() => {
                    Swal.fire({
                        text: store.getters.getFlightSupplierProfitsErrors[0], confirmButtonText: "Tekrar dene!",
                        icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                    });
                    submitButtonRef.value.disabled = false;
                    submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                });

        };

        store.dispatch(Actions.MARKETS_LIST_CACHE, {});
        const myMarkets = computed(() => {
            return store.getters.MarketsListCache;
        });

        store.dispatch(Actions.ACCOUNT_LIST_ALL, {});
        const myAccounts = computed(() => {
            const tmp= store.getters.accountList;
            //console.log(tmp)
            return tmp;
        });

        const myTagsMarkets = computed(() => {
            if (myMarkets.value.data) {
                let tmpList = [];
                for (let i = 0; i < myMarkets.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myMarkets.value.data[i].Title).trim() + "(" + myMarkets.value.data[i].Cur + ")",
                        title: (myMarkets.value.data[i].ShortTitle).trim(),
                        cur: myMarkets.value.data[i].Cur,
                        type: 'market',
                        ID: myMarkets.value.data[i].ID,
                    };
                }
                setTagInput(tmpList)
                return tmpList;
            } else {
                return [];
            }
        });

        const myTagsAccounts = computed(() => {
            if (myAccounts.value.data!== undefined) {
                let tmpList = [];
                for (let i = 0; i < myAccounts.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myAccounts.value.data[i].Title).trim(),
                        title: (myAccounts.value.data[i].Title).trim(),
                        type: 'Departments',
                        ID: myAccounts.value.data[i].ID,
                    };

                }
                setTagInputAccounts(tmpList)
                return tmpList;
            } else {
                return [];
            }
        });


        let tagify;
        let tagify2;
        const setTagInput = (wList) => {
            var input = document.querySelector('input[name=myTagsInput]');
// init Tagify script on the above inputs
            tagify = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });


        }
        const setTagInputAccounts = (wList) => {
            var input = document.querySelector('input[name=myTagsInputAccounts]');
// init Tagify script on the above inputs
            tagify2 = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });


        }
        function onAccountChange(e) {
            let tmpObj = JSON.parse(e.target.value);
            newItemData.value.itemAccount = tmpObj;
        }
        function onMarketChange(e) {
            //console.log(e.target.value)
            let tmpObj = JSON.parse(e.target.value);
            newItemData.value.itemMarket = tmpObj;
        }
        return {
            myDetails2,
            newItemData,
            myTagsMarkets,
            myTagsAccounts,
            validationSchema,
            submit,
            onAccountChange,
            onMarketChange,
            submitButtonRef,
            newRoomModalRef,
            dateConvert,
            airlineclassestype,
            arrAirlineClasses,
        };
    },
});
