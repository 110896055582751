
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"
import Tagify from '@yaireo/tagify';
import roomProfitType from "@/core/data/roomprofittype";
import roomProfitCalculateType from "@/core/data/roomProfitCalculateType";
import {dateConvert} from "@/core/filters/datatime";
import airlineclassestype from "@/core/data/airlineclassestype";

interface newItemData {
    itemTitle: string;
    itemStart: string;
    itemFinish: string;
    itemMarket: string;
    itemAccount: string;
    itemAirlineClasses: string;
}

export default defineComponent({
    name: "update-item-modal",
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    data() {
        return {
            startpicker: null,
            endpicker: null,
        }
    },
    methods: {
    },
    props: {
        supplierID: String,
        itemID: String,
    },
    watch: {
        itemID() {
            const payloadit = {
                "ID": this.itemID
            };
            this.accTagAddStatus = false;
            this.marTagAddStatus = false;
            store.dispatch(Actions.FLIGHT_SUPPLIER_PROFITS_DETAILS, payloadit);
        },
    },
    setup(props) {
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const newRoomModalRef = ref<null | HTMLElement>(null);
        const arrAirlineClasses = ref ([
          {  Type:airlineclassestype.TYPES[0].Value, OneWay : "",RoundTrip : "",},
          {  Type:airlineclassestype.TYPES[1].Value, OneWay : "",RoundTrip : "",},
          {  Type:airlineclassestype.TYPES[2].Value, OneWay : "",RoundTrip : "",},
          {  Type:airlineclassestype.TYPES[3].Value, OneWay : "",RoundTrip : "",},
        ]);


        function setDatePicker(sDate,fDate){
            let myConfigS = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                defaultDate: sDate,
            };

            let myConfigF = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                defaultDate: fDate,
            };

            let tmpFinishDate = flatpickr("#updateFinishDate", myConfigF);
            let tmpStartDate = flatpickr("#updateStartDate", myConfigS);

        }
        const myDetails2 = computed(() => {
            return store.getters.suppliersDetails;
        });

        const myDetails = computed(() => {
            let tmpArry = store.getters.flightSupplierProfitsDetails;
            if(tmpArry.ID!==undefined) {
                setData(tmpArry);

                if (tmpArry.StartDate) setDatePicker(dateConvert.method.convertToDate(tmpArry.StartDate), dateConvert.method.convertToDate(tmpArry.FinishDate))
            }
            return tmpArry;

        });

        const setData = (tmpArry) => {
            newItemData.value.itemTitle = tmpArry.Title;
            newItemData.value.itemStart = dateConvert.method.dateToflatpicker(tmpArry.StartDate);
            newItemData.value.itemFinish = dateConvert.method.dateToflatpicker(tmpArry.FinishDate);
            newItemData.value.itemMarket = tmpArry.Markets;
            newItemData.value.itemAccount = tmpArry.Accounts;
            newItemData.value.itemAirlineClasses = JSON.parse(tmpArry.AirlineClasses);
            arrAirlineClasses.value = [];
              for (let i=0; i<newItemData.value.itemAirlineClasses.length; i++){
                arrAirlineClasses.value.push(newItemData.value.itemAirlineClasses[i] as any);
              }
        }

        const newItemData = ref<newItemData>({
            itemTitle: "",
            itemStart: "",
            itemFinish: "",
            itemMarket: "",
            itemAccount: "",
            itemAirlineClasses: "",
        });

        const validationSchema = Yup.object().shape({
            itemTitle: Yup.string().required().label("Başlık"),
            itemStart: Yup.string().required().label("Başlangıç Tarihi"),
            itemFinish: Yup.string().required().label("Bitiş Tarihi"),
        });
        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }
            //Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");


            const payload1 = {
                ID: props.itemID,
                SupplierID: myDetails2.value.ID,
                Title: newItemData.value.itemTitle,
                StartDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemStart),
                FinishDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemFinish),
                Markets: (typeof newItemData.value.itemMarket !="string") ? (JSON.stringify(newItemData.value.itemMarket)).replace(/\\/g, '') : '""',
                Accounts: (typeof newItemData.value.itemAccount !="string") ? (JSON.stringify(newItemData.value.itemAccount)).replace(/\\/g, '') : '""',
                AirlineClasses: (typeof newItemData.value.itemAirlineClasses !="string") ? (JSON.stringify(newItemData.value.itemAirlineClasses)).replace(/\\/g, '') : '""',
            }


            store.dispatch(Actions.FLIGHT_SUPPLIER_PROFITS_EDIT, payload1)
                .then(() => {
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Geri Dön!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(newRoomModalRef.value);
                        // Go to page after successfully login
                        //router.push({name: "userlist"});
                    });
                }).catch(() => {
                Swal.fire({
                    text: store.getters.getFlightSupplierProfitsErrors[0],
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Tekrar dene!",
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-danger",
                    },
                });
                submitButtonRef.value.disabled = false;
                submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });

        };

        store.dispatch(Actions.MARKETS_LIST_CACHE, {});
        const myMarkets = computed(() => {
            return store.getters.MarketsListCache;
        });

        store.dispatch(Actions.ACCOUNT_LIST_ALL, {});
        const myAccounts = computed(() => {
            return store.getters.accountList;
        });

        let marTagAddStatus = false;
        const myTagsMarkets = computed(() => {
            if (myMarkets.value.data && myDetails.value.ID) {
                let tmpList = [];
                let tmpSelList = [];
                let selData = JSON.parse(myDetails.value.Markets);
                for (let i = 0; i < myMarkets.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myMarkets.value.data[i].Title).trim() + "(" + myMarkets.value.data[i].Cur + ")",
                        title: (myMarkets.value.data[i].ShortTitle).trim(),
                        cur: myMarkets.value.data[i].Cur,
                        type: 'market',
                        ID: myMarkets.value.data[i].ID,
                    };
                    for (let is = 0; is < selData.length; is++) {
                        if (selData[is].ID == myMarkets.value.data[i].ID) {
                            tmpSelList[is] = {
                                value: (myMarkets.value.data[i].Title).trim() + "(" + myMarkets.value.data[i].Cur + ")",
                                title: (myMarkets.value.data[i].ShortTitle).trim(),
                                cur: myMarkets.value.data[i].Cur,
                                type: 'market',
                                ID: myMarkets.value.data[i].ID,
                            };
                        }
                    }
                }

                if(marTagAddStatus == false) {
                    setTagInput(tmpList)
                    marTagAddStatus=true;
                }
                let tags = (document.getElementById('myTagsMarkets') as HTMLInputElement);
                if(tags.value!='')tagifyMarket.removeAllTags();
                if(tmpSelList.length>0) {
                    tagifyMarket.addTags(tmpSelList);
                }

                return tmpList;

            } else {
                return [];
            }
        });

        let accTagAddStatus = false;
        let tmpSelList = [];
        const myTagsAccounts = computed(() => {
            if (myAccounts.value.data !== undefined && myDetails.value.ID) {
                let tmpList = [];

                let selData = JSON.parse(myDetails.value.Accounts);

                for (let i = 0; i < myAccounts.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myAccounts.value.data[i].Title).trim(),
                        title: (myAccounts.value.data[i].Title).trim(),
                        type: 'Accounts',
                        ID: myAccounts.value.data[i].ID,
                    };
                    for (let is = 0; is < selData.length; is++) {
                        if (selData[is].ID == myAccounts.value.data[i].ID) {
                            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                            tmpSelList[is] = {
                                value: (myAccounts.value.data[i].Title).trim(),
                                title: (myAccounts.value.data[i].Title).trim(),
                                type: 'Accounts',
                                ID: myAccounts.value.data[i].ID,
                            };
                        }

                    }
                }
                if(accTagAddStatus==false){
                    setTagInputAccounts(tmpList)
                    accTagAddStatus=true;
                }
                let tags = (document.getElementById('myTagsAccounts') as HTMLInputElement);
                if(tags.value!='')tagifyAccount.removeAllTags();
                if(tmpSelList.length>0) {
                    tagifyAccount.addTags(tmpSelList);
                }
                return tmpList;
            } else {
                return [];
            }
        });

        function testFunc(){
            tagifyAccount.removeAllTags();
            //tagifyAccount.addTags(tmpSelList);
        }

        let tagifyMarket;
        let tagifyAccount;
        const setTagInput = (wList) => {
            var input = document.querySelector('input[name=myTagsUpdateInput]');
            tagifyMarket = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });

        }
        const setTagInputAccounts = (wList) => {
            var input = document.querySelector('input[name=myTagsUpdateInputAccount]');
            tagifyAccount = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });

        }

        function onAccountChange(e) {
            if(e.target.value !== "") {
                let tmpObj = JSON.parse(e.target.value);
                newItemData.value.itemAccount = tmpObj;
            }else{
                let tmpObj = "";
                newItemData.value.itemAccount = tmpObj;
            }
        }

        function onMarketChange(e) {
            if(e.target.value !== "") {
                let tmpObj = JSON.parse(e.target.value);
                newItemData.value.itemMarket = tmpObj;
            }else{
                let tmpObj = "";
                newItemData.value.itemMarket = tmpObj;
            }
        }

        return {
            myDetails,
            myDetails2,
            newItemData,
            myTagsMarkets,
            myTagsAccounts,
            validationSchema,
            submit,
            onAccountChange,
            onMarketChange,
            submitButtonRef,
            newRoomModalRef,
            roomProfitType,
            roomProfitCalculateType,
            dateConvert,
            accTagAddStatus,
            marTagAddStatus,
            testFunc,
            tmpSelList,
            airlineclassestype,
            arrAirlineClasses,
        };
    },
});
